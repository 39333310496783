'use strict';

/*function topPosition(el) {
    const rect = el.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return rect.top + scrollTop;
}*/

$(document).ready(function () {
    // Initialize scroll inside page
    $('a[href^="#"]').juizScrollTo();

    // Initialize popover
    $("[data-toggle=popover]").popover();

    // Collapse trick
    $('.collapse').on('show.bs.collapse', function (e) {
        var btn = $(e.target).parent('div').find('a[data-toggle="collapse"]');
        btn.slideUp();
    });
    $('.collapse').on('hidden.bs.collapse', function (e) {
        var btn = $(e.target).parent('div').find('a[data-toggle="collapse"]');
        btn.slideDown();
    });

    // if not on touch device
    if (!('ontouchstart' in window)) {
        skrollr.init();
    }

    // $('.show-photo').on('click', function(){
    //     $('.photo', this).fadeToggle();
    // });

    var options = {
        useEasing: true,
        useGrouping: true,
        separator: ' ',
        decimal: '',
        suffix: ''
    };
    var optionstm4 = {
        useEasing: true,
        useGrouping: true,
        separator: ' ',
        decimal: '',
        suffix: '<span class="static-bold">%</span>'
    };
    var tm1_1 = new CountUp('tm1-count-215', 0, 215, 0, 2.5, options);
    var tm1 = new CountUp('tm1-count-206', 0, 206, 0, 2.5, options);
    var tm2 = new CountUp('tm2-count-16k', 0, 16765, 0, 2.5, options);
    var tm2_2 = new CountUp('tm2-count-1800', 0, 1800, 0, 2.5, options);
    var tm3 = new CountUp('tm3-count-117k', 0, 117970, 0, 2.5, options);
    var tm3_3 = new CountUp('tm3-count-34722', 0, 34722, 0, 2.5, options);
    var tm4 = new CountUp('tm4-count-80', 0, 60, 0, 2.5, optionstm4);
    var tm4_4 = new CountUp('tm4-count-60', 0, 60, 0, 2.5, optionstm4);

    var $delta = 300;
    var $deltalong = 600;
    var $timeline1 = $('#construire-before').offset().top - $deltalong;
    var $timeline2 = $('#rehabiliter-before').offset().top - $deltalong;
    var $timeline3 = $('#relation-client').offset().top - $delta;
    var $timeline4 = $('#rse').offset().top - $delta;

    $(window).scroll(function () {
        if ($(window).scrollTop() > $timeline1) {
            tm1.start();tm1_1.start();
        }
        if ($(window).scrollTop() > $timeline2) {
            tm2.start();tm2_2.start();
        }
        if ($(window).scrollTop() > $timeline3) {
            tm3.start();tm3_3.start();
        }
        if ($(window).scrollTop() > $timeline4) {
            tm4.start();tm4_4.start();
        }
    });
    /*if (!demo.error) {
    } else {
        console.error(demo.error);
    }*/
});